import { PAGE_KEY_IMPRINT } from "@constants";
import { escapeReact } from "@utils/react";
import React from "react";
import { mergeTemplateItems } from "../common/utils";
import { createLazyTemplateItem } from "../utils";

const TEMPLATE_KEY = "sites/ImprintTemplate";

const ImprintTemplate = props => {
  const defaultTemplate = [
    {
      component: "core/TitledParagraph",
      props: props.lead,
      
    },
    {
      component: "core/TitledParagraph",
      props: {
        title: props.address.title,
        text: escapeReact(
          [
            props.address.address1,
            props.address.address2,
            [props.address.postalCode, props.address.city]
              .filter(Boolean)
              .join(", "),
            props.address.country
          ]
            .filter(Boolean)
            .join("\n"),
          null,
          true
        )
      },
      
    },
    {
      component: "core/TitledParagraph",
      props: props.representative,
      
    },
    {
      component: "core/TitledParagraph",
      props: {
        title: props.contact.title,
        text: [
          <React.Fragment key={0}>{props.contact.phone}</React.Fragment>,
          <br key={1} />,
          <React.Fragment key={2}>{props.contact.email}</React.Fragment>
        ]
      },
      
    },
    ...props.items.map(item => ({
      component: "core/TitledParagraph",
      props: item
    })),
    ...props.widgets
  ];

  return mergeTemplateItems(defaultTemplate, props.templates).map(
    createLazyTemplateItem
  );
};

ImprintTemplate.mapValueToProps = value => ({
  lead: value.imprint.lead,
  address: value.imprint.address,
  representative: value.imprint.representative,
  contact: value.imprint.contact,
  items: value.imprint.items
  // searchBanner: value.searchBanner,
  // imageFootnoteCardDeck: value.imageFootnoteCardDeck,
});

ImprintTemplate.mapValueToTemplateProps = {
  pageKey: PAGE_KEY_IMPRINT,
  templateKey: TEMPLATE_KEY
};

export default ImprintTemplate;
